export const breakpoints = {
    xs: "480px",
    sm: "768px",
    md: "1024px",
    lg: "1366px",
    xl: "1920px",
    numeric: {
        xs: 480,
        sm: 768,
        md: 1024,
        mdl: 1192,
        lg: 1366,
        xl: 1920
    }
};
